import loadable from "@loadable/component"
import React from "react"
import InfoMapSort from "../components/SearchResults/InfoMapSort/InfoMapSort"
import NoResults from "../components/SearchResults/NoResults/NoResults"
import PropertyList from "../components/SearchResults/PropertyList/PropertyList"
import SearchFilters from "../components/SearchResults/SearchFilters/SearchFilters"
import SearchPagination from "../components/SearchResults/SearchPagination/SearchPagination"
import useInitPropertyData from "../components/SearchResults/hooks/useInitPropertyData"
import useUrlData from "../components/SearchResults/hooks/useUrlData"
import { usePropertyStore } from "../components/SearchResults/store/propertyStore"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { getH1Text, getSeoDesc } from "../lib/searchUtils"
import "../styles/search-results.scss"
import _ from "lodash"


const PropertyMap = loadable(() =>
  import("../components/SearchResults/map/RenderMap")
)

const SearchResults = () => {
  useInitPropertyData()
  const { searchResult } = usePropertyStore((state) => ({
    searchResult: state.searchResult,
    pDepartment: state.pDepartment,
  }))

  const showMap = usePropertyStore((state) => state.showMap)

 

  return (
    <Layout
      layout="search-results-page"
      pageData={{
        page_class: "search-results-page",
        layout: "search-results",
      }}
    >
         
      <div className="">
        {showMap ? (
          <>
            <SearchFilters />
            {searchResult?.nbHits > 0 ? (
              <div className="search-map-wrapper">
                <PropertyMap />
              </div>
            ) : (
              <div className="search-results-section">
                <NoResults />
              </div>
            )}
          </>
        ) : (
          <>
            <SearchFilters />
            {/* <AmenitiesSelect /> */}

            <div className="search-results-section">
              <InfoMapSort />
              <PropertyList />
              {searchResult?.hits && searchResult?.intialized &&
              <SearchPagination />
              }
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export const Head = () => {
 const { searchResult } = usePropertyStore((state) => ({
    searchResult: state.searchResult,
    pDepartment: state.pDepartment,
  }))
  const pageUrlData = useUrlData()

  const h1Text = getH1Text(pageUrlData)
  const seoDesc = getSeoDesc(pageUrlData)


   const total = searchResult?.hits?.length

  const entity = {
    name: h1Text,
    desc:seoDesc,
    list: _.map(searchResult?.hits, o =>
      _.extend(
        {
          name: `${o.bedroom} bed ${o?.building?.length>0?o.building[0]:""+" "}${
            o?.search_type === "sales" ? "for sale" : "to rent"
          } in ${o?.display_address}`,
          url:
            o?.search_type === "sales"
              ? process.env.GATSBY_SITE_URL +
                "/property-for-sale/" +
                o?.slug +
                "-" +
                o?.objectID
              : process.env.GATSBY_SITE_URL +
                "/property-for-rent/" +
                o?.slug +
                "-" +
                o?.objectID,
        },
        o
      )
    ),
  }

  var ldJson = {
    "@context": "http://schema.org",
    "@type": "SearchResultsPage",
    publisher: {
      "@type": "Corporation",
      name: "Baytify",
      logo: {
        "@type": "ImageObject",
        url: process.env.GATSBY_SITE_URL + `/images/logo.png`,
        width: 250,
        height: 100,
      },
    },
  }

   if (!_.isEmpty(entity)) {
    var itemListElement = []
    if (!_.isEmpty(entity.list)) {
      _.forEach(entity.list, (val, key) => {
        console.log(entity,"ennnn")
        itemListElement.push({
          "@type": "ListItem",
          position: key + 1,
          url: val.url,
          name: val.name,
        })
      })
      ldJson["mainEntity"] = {
        "@type": "ItemList",
        numberOfItems: total,
        name: entity?.name,
        description: entity?.desc,
        itemListElement: itemListElement,
      }
    }
  }
  return( <Seo title={h1Text} description={`${seoDesc}`} >
     <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
          />
  </Seo>
  )
}

export default SearchResults
